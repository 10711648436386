import React, { useState, useEffect } from 'react';
import { Order, getExistingOrders, calculateDistance, checkAvailability, getGooglePlaceSuggestions, canScheduleOrder } from '../../../services/orderService';
import { Character, getCharacters } from '../../../services/characterService';
import { Driver, getDrivers } from '../../../services/driverService';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Listbox } from '@headlessui/react';
import { format } from 'date-fns';
import Alert from '../../../shared/AlertPopup';
import CustomDatePicker from '../CustomDatePicker';
import CustomTimerInput from './CustomTimeInput';

interface OrderDetailsSectionProps {
  updateOrderData: (data: Partial<Order>) => void;
  setOrderAddress: (address: string) => void;
  setOrderZone: (zone: string) => void;
  initialData?: Partial<Order>;
  extraCharacters: ExtraCharacter[];  
  onAddExtraCharacter: () => void;    
  onRemoveExtraCharacter: (index: number) => void;  
}

interface ExtraCharacter {
  character: Character;
  modelFee: number;
}

interface OrderDetails {
  characterType: string;
  character: string;
  date: string;
  startTime: string;
  endTime: string;
  driver: string;
  address: string;
  addressDetails: string;
  zone: string;
  notes: string;
}

const OrderDetailsSection: React.FC<OrderDetailsSectionProps> = ({ updateOrderData, setOrderAddress, setOrderZone, initialData, extraCharacters, onAddExtraCharacter, onRemoveExtraCharacter }) => {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [characterTypes, setCharacterTypes] = useState<string[]>([]);
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [conflictDetails, setConflictDetails] = useState<{ date: string, startTime: string, endTime: string, address: string, breakAfter: number } | null>(null);
  const [showConflictPopup, setShowConflictPopup] = useState(false);
  const [lastOrderAddress, setLastOrderAddress] = useState('');
  const [details, setDetails] = useState<OrderDetails>({
    characterType: initialData?.character?.characterType || '',
    character: initialData?.character?._id || '',
    date: initialData?.period?.startDate || '',
    startTime: initialData?.period?.startTime || '',
    endTime: initialData?.period?.endTime || '',
    driver: initialData?.driver?._id || '',
    address: initialData?.address || '',
    addressDetails: initialData?.addressDetails || '',
    zone: initialData?.zone || '',
    notes: initialData?.notes || ''
  });
  const [extraChars, setExtraChars] = useState<ExtraCharacter[]>(extraCharacters);
  const [showDistanceWarning, setShowDistanceWarning] = useState(false);
  const [distanceInfo, setDistanceInfo] = useState({ distance: '', duration: '' });
  const [availabilityConflict, setAvailabilityConflict] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedCharacters, fetchedDrivers] = await Promise.all([getCharacters(), getDrivers()]);
        
        // Sortarea alfabetică a caracterelor
        const sortedCharacters = fetchedCharacters.sort((a, b) => {
          const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
          const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
          return nameA.localeCompare(nameB);
        });
        
        // Sortarea alfabetică a șoferilor
        const sortedDrivers = fetchedDrivers.sort((a, b) => {
          const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
          const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
          return nameA.localeCompare(nameB);
        });

        setCharacters(sortedCharacters);
        setDrivers(sortedDrivers);
        
        // Sortarea tipurilor de caractere
        const types = Array.from(new Set(sortedCharacters.map(char => char.characterType))).sort();
        setCharacterTypes(types);

        if (initialData?.character) {
          setDetails(prev => ({
            ...prev,
            characterType: initialData.character?.characterType || prev.characterType,
            character: initialData.character?._id || prev.character
          }));
        } else if (types.length > 0) {
          setDetails(prev => ({ ...prev, characterType: types[0] }));
        }
      } catch (error) {
        console.error('Eroare la obținerea datelor:', error);
      }
    };

    if (!characters.length || !drivers.length) {
      fetchData();
    }
  }, [initialData, characters.length, drivers.length]);

  useEffect(() => {
    updateOrderData({
      character: characters.find(char => char._id === details.character),
      period: {
        startDate: details.date,
        startTime: details.startTime,
        endDate: details.date,
        endTime: details.endTime,
      },
      driver: drivers.find(d => d._id === details.driver),
      address: details.address,
      zone: details.zone,
      notes: details.notes,
      extraCharacters: extraChars
    });
  }, [details, extraChars, updateOrderData, characters, drivers]);

  const handleAddressChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setDetails(prev => ({ ...prev, address: input }));
    setOrderAddress(input);

    if (input.length > 3) {
      try {
        const suggestions = await getGooglePlaceSuggestions(input);
        const places = suggestions.predictions.map((prediction: any) => prediction.description);
        setAddressSuggestions(places);
      } catch (error) {
        console.error('Eroare la obținerea sugestiilor de adrese:', error);
      }
    } else {
      setAddressSuggestions([]);
    }
  };

  const handleAddressSuggestionClick = async (suggestion: string) => {
    setDetails(prev => ({ ...prev, address: suggestion }));
    setOrderAddress(suggestion);
    setAddressSuggestions([]);

    if (details.character && details.date) {
      try {
        const existingOrders = await getExistingOrders(details.character, details.date);
        if (existingOrders.length > 0) {
          const lastOrder = existingOrders[existingOrders.length - 1];
          setLastOrderAddress(lastOrder.address);
          const { distance, duration } = await calculateDistance(lastOrder.address, suggestion);
          setDistanceInfo({ distance, duration });
          setShowDistanceWarning(true);
        }
      } catch (error) {
        console.error('Eroare la calculul distanței:', error);
      }
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setDetails(prev => ({ ...prev, [name]: value }));

    if (name === 'zone') {
      setOrderZone(value);
    } else if (name === 'addressDetails') {
      updateOrderData({ addressDetails: value });
    }

    setAvailabilityConflict(false);
    setShowConflictPopup(false);
    setConflictDetails(null);

    if ((name === 'startTime' || name === 'endTime') && details.character && details.date) {
      try {
        const existingOrders = await getExistingOrders(details.character, details.date);
        if (existingOrders.length > 0) {
          const lastOrder = existingOrders[existingOrders.length - 1];
          const breakAfter = lastOrder.breakAfter || 10;

          const canSchedule = canScheduleOrder(
            lastOrder.period.endTime,
            new Date(lastOrder.period.endDate),
            value,
            breakAfter
          );

          if (!canSchedule) {
            setConflictDetails({
              date: lastOrder.period.startDate,
              startTime: lastOrder.period.startTime,
              endTime: lastOrder.period.endTime,
              address: lastOrder.address,
              breakAfter: lastOrder.breakAfter
            });
            setShowConflictPopup(true);
            setAvailabilityConflict(true);
          }
        }
      } catch (error) {
        console.error('Eroare la verificarea disponibilității:', error);
      }
    }
  };

  const handleCharacterTypeChange = (selectedType: string) => {
    setDetails(prev => ({
      ...prev,
      characterType: selectedType,
      character: ''
    }));
  };

  const handleAddExtraCharacter = () => {
    const defaultCharacter = characters.find(c => c.characterType === details.characterType);
    if (defaultCharacter) {
      setExtraChars(prev => [...prev, { character: defaultCharacter, modelFee: 0 }]);
    }
  };

  const handleRemoveExtraCharacter = (index: number) => {
    setExtraChars(prev => prev.filter((_, i) => i !== index));
  };

  const handleConfirmDistance = () => {
    setShowDistanceWarning(false);
  };

  const handleCancelDistance = () => {
    setShowDistanceWarning(false);
    setDetails(prev => ({ ...prev, address: '' }));
  };

  const renderConflictPopup = () => {
    if (conflictDetails && showConflictPopup) {
      const formattedDate = format(new Date(conflictDetails.date), 'dd.MM.yyyy');
      return (
        <div className="col-span-full mt-2">
          <div className="popup bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold block mb-2">Conflict de programare!</strong>
            <span className="block mt-2">
              În data de {formattedDate}, la ora {conflictDetails.startTime}, există deja o comandă la adresa {conflictDetails.address}. 
              Comanda durează până la ora {conflictDetails.endTime}, iar pauza necesară după aceasta este de {conflictDetails.breakAfter} minute.
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  // Modificat pentru a sorta opțiunile alfabetic
  const renderListbox = (
    label: string,
    name: string,
    value: string,
    options: { id: string; name: string }[],
    onChange: (value: string) => void
  ) => (
    <div className="sm:col-span-1">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <Listbox value={value} onChange={onChange}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{options.find(opt => opt.id === value)?.name || 'Selectează'}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                <Listbox.Option
                  key={option.id}
                  value={option.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-3 pr-9 ${
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    }`
                  }
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                        {option.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
    </div>
  );

  return (
    <div className="space-y-6 sm:space-y-5 border-t border-gray-200 pt-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Detalii comandă</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Completați informațiile despre comandă.</p>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
        {renderListbox(
          'Tip caracter',
          'characterType',
          details.characterType,
          characterTypes.map(type => ({ id: type, name: type })),
          handleCharacterTypeChange
        )}

        {renderListbox(
          'Model',
          'character',
          details.character,
          characters
            .filter(char => char.characterType === details.characterType)
            .map(char => ({ id: char._id, name: `${char.firstName} ${char.lastName}` })),
          (value) => setDetails(prev => ({ ...prev, character: value }))
        )}

        {extraChars.map((extraChar, index) => (
          <React.Fragment key={index}>
            {renderListbox(
              `Tip caracter extra ${index + 1}`,
              `extraCharacterType-${index}`,
              extraChar.character.characterType,
              characterTypes.map(type => ({ id: type, name: type })),
              (value) => {
                const newCharacter = characters.find(c => c.characterType === value);
                if (newCharacter) {
                  setExtraChars(prev => 
                    prev.map((char, i) => 
                      i === index ? { ...char, character: newCharacter } : char
                    )
                  );
                }
              }
            )}

            {renderListbox(
              `Model extra ${index + 1}`,
              `extraCharacter-${index}`,
              extraChar.character._id,
              characters
                .filter(c => c.characterType === extraChar.character.characterType)
                .map(char => ({ id: char._id, name: `${char.firstName} ${char.lastName}` })),
              (value) => {
                const selectedCharacter = characters.find(c => c._id === value);
                if (selectedCharacter) {
                  setExtraChars(prev => 
                    prev.map((char, i) => 
                      i === index ? { ...char, character: selectedCharacter } : char
                    )
                  );
                }
              }
            )}
          </React.Fragment>
        ))}

        {extraChars.length > 0 ? (
          <div className="sm:col-span-2 flex justify-between items-center mt-2">
            <button
              type="button"
              onClick={handleAddExtraCharacter}
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Adaugă caracter extra
            </button>
            <button
              type="button"
              onClick={() => handleRemoveExtraCharacter(extraChars.length - 1)}
              className="text-red-600 hover:text-red-800 font-medium"
            >
              Șterge
            </button>
          </div>
        ) : (
          <div className="sm:col-span-2">
            <button
              type="button"
              onClick={handleAddExtraCharacter}
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Adaugă caracter extra
            </button>
          </div>
        )}

        <div className="sm:col-span-1">
          <div className="custom-datepicker-wrapper">
            <CustomDatePicker
              selectedDate={details.date}
              onChange={(newDate) => setDetails((prev) => ({ ...prev, date: newDate }))}
              label="Data"
              name="date"
              placeholder="Alege data"
            />
          </div>
        </div>

        {renderListbox(
          'Șofer',
          'driver',
          details.driver,
          drivers.map(driver => ({ id: driver._id, name: `${driver.firstName} ${driver.lastName}` })),
          (value) => setDetails(prev => ({ ...prev, driver: value }))
        )}

        <div className="sm:col-span-1">
          <div className="mt-[0.7rem]">
            <CustomTimerInput
              selectedTime={details.startTime}
              onChange={(newTime: string) => {
                setDetails((prev) => ({ ...prev, startTime: newTime }));
                handleChange({ target: { name: 'startTime', value: newTime } } as React.ChangeEvent<HTMLInputElement>);
              }}
              label="Ora de început"
              name="startTime"
              placeholder="Alege ora de început"
            />
          </div>
        </div>

        <div className="sm:col-span-1">
          <div className="mt-[0.7rem]">
            <CustomTimerInput
              selectedTime={details.endTime}
              onChange={(newTime: string) => {
                setDetails((prev) => ({ ...prev, endTime: newTime }));
                handleChange({ target: { name: 'endTime', value: newTime } } as React.ChangeEvent<HTMLInputElement>);
              }}
              label="Ora de sfârșit"
              name="endTime"
              placeholder="Alege ora de sfârșit"
            />
          </div>
        </div>

        {renderConflictPopup()}

        <div className="sm:col-span-2 space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                Adresă
              </label>
              <div className="mt-2 relative">
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={details.address}
                  onChange={handleAddressChange}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {addressSuggestions.length > 0 && (
                  <ul className="absolute z-10 mt-1 w-full bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {addressSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleAddressSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="addressDetails" className="block text-sm font-medium leading-6 text-gray-900">
                Detalii suplimentare adresă
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="addressDetails"
                  id="addressDetails"
                  value={details.addressDetails}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label htmlFor="zone" className="block text-sm font-medium leading-6 text-gray-900">
                Zonă
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="zone"
                  id="zone"
                  value={details.zone}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="hidden sm:block"></div>
          </div>
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
            Mențiuni
          </label>
          <div className="mt-2">
            <textarea
              name="notes"
              id="notes"
              value={details.notes}
              onChange={handleChange}
              rows={4}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>

      <Alert
        isOpen={showDistanceWarning}
        onClose={() => setShowDistanceWarning(false)}
        title="Avertizare distanță"
        message={
          <div>
            <p>Distanța între ultima comandă la adresa <strong>{lastOrderAddress}</strong> și noua adresă <strong>{details.address}</strong> este de <strong>{distanceInfo.distance}</strong>.</p>
            <br />
            <p>Timpul estimat de călătorie este de <strong>{distanceInfo.duration.replace('minutes', 'minute').replace('minute', 'minute')}</strong>.</p>
            <p className="">Doriți să confirmați această adresă?</p>
          </div>
        }
        type="warning"
        confirmText="Confirmă"
        cancelText="Anulează"
        onConfirm={handleConfirmDistance}
        onCancel={handleCancelDistance}
      />
    </div>
  );
};

export default OrderDetailsSection;